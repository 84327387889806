import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as Realm from "realm-web";
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const Export = ({ setLoggedIn }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [approvedTimesData, setApprovedTimesData] = useState({});
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const initialize = async () => {
      const isValid = await validateToken(token);
      if (!isValid) {
        if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
              await fetchData();
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
        setLoggedIn(true);
        await fetchData();
      }
    };

    initialize();
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const handleExportMonth = async (month) => {
    const employeesToExport = selectedEmployee
      ? employeeList.filter(employee => employee._id === selectedEmployee)
      : employeeList;
  
    const enrichedEmployees = employeesToExport.map(employee => {
      const approvedTimes = approvedTimesData[employee.employeeId]
        .filter(time => new Date(time.date).getMonth() === month)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
  
      const timeRows = approvedTimes.map(time => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        const durationMinutes = (end - start) / 60000;
        const durationHours = Math.floor(durationMinutes / 60);
        const durationRemainderMinutes = durationMinutes % 60;
  
        return {
          Datum: new Date(time.date).toLocaleDateString(),
          Start: time.startTime,
          Ende: time.endTime,
          'Gesamte Zeit': `${durationHours}:${durationRemainderMinutes.toString().padStart(2, '0')} Stunden`
        };
      });
  
      const totalWorkedMinutes = approvedTimes.reduce((total, time) => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        return total + (end - start) / 60000;
      }, 0);
      const totalWorkedHours = Math.floor(totalWorkedMinutes / 60);
      const totalWorkedRemainderMinutes = totalWorkedMinutes % 60;
  
      timeRows.push({
        Datum: "Total",
        Start: "",
        Ende: "",
        'Gesamte Zeit': `${totalWorkedHours}:${totalWorkedRemainderMinutes.toString().padStart(2, '0')} Stunden`
      });
  
      return {
        Name: `${employee.firstName} ${employee.lastName}`,
        Personalnummer: employee.employeeId,
        timeRows
      };
    });
  
    const worksheetData = enrichedEmployees.flatMap(employee => [
      [{ v: employee.Name }, { v: employee.Personalnummer }],
      ["Datum", "Start", "Ende", "Gesamte Zeit"],
      ...employee.timeRows.map(row => [row.Datum, row.Start, row.End, row['Gesamte Zeit']]),
      []
    ]);
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `Month_${month}`);
    XLSX.writeFile(workbook, `Month_${month}_${selectedYear}_Employees.xlsx`);
  };

  const fetchEmployees = async () => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
    };

    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEELIST_URL, requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching employee data:', result.error);
      } else {
        setEmployeeList(result);
        return result;
      }
    } catch (error) {
      console.error('Error:', error);
    }
    return [];
  };

  const fetchApprovedTimesForYear = async (employeeId, year) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token,
        email: employeeId,
        year: year.toString()
      }),
      redirect: 'follow'
    };

    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching time entries:', result.error);
        return [];
      } else {
        return result.filter(time => time.status === 'approved');
      }
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const employees = await fetchEmployees();
    const approvedTimesData = {};

    await Promise.all(employees.map(async (employee) => {
      const approvedTimes = await fetchApprovedTimesForYear(employee.email, selectedYear);
      approvedTimesData[employee.employeeId] = approvedTimes;
    }));

    setApprovedTimesData(approvedTimesData);
    setLoading(false);
  };

  const calculateWorkDays = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let workDays = 0;
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        workDays++;
      }
    }
    return workDays;
  };

  const handleMonthSelection = (month) => {
    let newSelectedMonths;
    if (selectedMonths.includes(month)) {
      newSelectedMonths = selectedMonths.filter(m => m !== month);
    } else {
      newSelectedMonths = [...selectedMonths, month];
    }
    setSelectedMonths(newSelectedMonths);
  };

  const handleExportSelectedMonths = async () => {
    const employeesToExport = selectedEmployee
      ? employeeList.filter(employee => employee._id === selectedEmployee)
      : employeeList;
  
    const enrichedEmployees = employeesToExport.map(employee => {
      const approvedTimes = approvedTimesData[employee.employeeId]
        .filter(time => selectedMonths.includes(new Date(time.date).getMonth()))
        .sort((a, b) => new Date(a.date) - new Date(b.date));
  
      const timeRows = approvedTimes.map(time => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        const durationMinutes = (end - start) / 60000;
        const durationHours = Math.floor(durationMinutes / 60);
        const durationRemainderMinutes = durationMinutes % 60;
  
        return {
          Datum: new Date(time.date).toLocaleDateString(),
          Start: time.startTime,
          Ende: time.endTime,
          'Gesamte Zeit': `${durationHours}:${durationRemainderMinutes.toString().padStart(2, '0')} Stunden`
        };
      });
  
      const totalWorkedMinutes = approvedTimes.reduce((total, time) => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        return total + (end - start) / 60000;
      }, 0);
      const totalWorkedHours = Math.floor(totalWorkedMinutes / 60);
      const totalWorkedRemainderMinutes = totalWorkedMinutes % 60;
  
      timeRows.push({
        Datum: "Total",
        Start: "",
        Ende: "",
        'Gesamte Zeit': `${totalWorkedHours}:${totalWorkedRemainderMinutes.toString().padStart(2, '0')} Stunden`
      });
  
      return {
        Name: `${employee.firstName} ${employee.lastName}`,
        Personalnummer: employee.employeeId,
        timeRows
      };
    });
  
    const worksheetData = enrichedEmployees.flatMap(employee => [
      [{ v: employee.Name }, { v: employee.Personalnummer }],
      ["Datum", "Start", "Ende", "Gesamte Zeit"],
      ...employee.timeRows.map(row => [row.Datum, row.Start, row.End, row['Gesamte Zeit']]),
      []
    ]);
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SelectedMonths');
    XLSX.writeFile(workbook, `SelectedMonths_${selectedYear}_Employees.xlsx`);
  };

  const handleExportFullYear = async () => {
    const employeesToExport = selectedEmployee
      ? employeeList.filter(employee => employee._id === selectedEmployee)
      : employeeList;
  
    const enrichedEmployees = employeesToExport.map(employee => {
      const approvedTimes = approvedTimesData[employee.employeeId];
  
      const totalWorkedMinutes = approvedTimes.reduce((total, time) => {
        const [startHours, startMinutes] = time.startTime.split(':').map(Number);
        const [endHours, endMinutes] = time.endTime.split(':').map(Number);
        const start = new Date(time.date);
        const end = new Date(time.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        return total + (end - start) / 60000;
      }, 0);
      const totalWorkedHours = Math.floor(totalWorkedMinutes / 60);
      const totalWorkedRemainderMinutes = totalWorkedMinutes % 60;
  
      return {
        Name: `${employee.firstName} ${employee.lastName}`,
        Personalnummer: employee.employeeId,
        'Gesamte Zeit': `${totalWorkedHours}:${totalWorkedRemainderMinutes.toString().padStart(2, '0')} hours`
      };
    });
  
    const worksheetData = [
      ["Name", "Personalnummer", "Gesamte Zeit"],
      ...enrichedEmployees.map(employee => [
        employee.Name,
        employee.Personalnummer,
        employee['Gesamte Zeit']
      ])
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Yearly');
    XLSX.writeFile(workbook, `Yearly_${selectedYear}_Employees.xlsx`);
  };

  const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
  const years = Array.from({ length: 1 }, (_, i) => new Date().getFullYear() - i);

  return (
    <div className="px-[83px] py-[48px]">
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}>
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <h1 className="text-[24px] leading-[32px] font-[700]">Zeitexport</h1>
          <p className='text-[12px] leading-[18px] font-[500] mb-[34px]'>Erfasste Mitarbeiterzeiten exportieren</p>
          <p className='text-[12px] leading-[18px] font-[500]'>Jahr auswählen</p>
          <div className="flex items-center mb-[16px] space-x-4 h-[40px]">
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              className="border rounded py-2 px-3 w-[60%] h-[40px]"
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <button className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded-[8px] w-[20%] h-[40px] flex items-center justify-center" onClick={handleExportSelectedMonths}>
              {selectedMonths.length} Monate
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
            <button className={`bg-[#0000FF] text-white py-2 px-4 rounded-[8px] ${loading ? 'cursor-wait' : 'cursor-pointer'} w-[20%] h-[40px] flex items-center justify-center`} onClick={handleExportFullYear} disabled={loading}>
              Export Jahr {selectedYear}
              <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
            </button>
          </div>
          
          <div>
            {monthNames.map((month, index) => (
              <div key={index} className="flex items-center justify-between h-[80px] p-4 border-b bg-[#FCFCFC] mb-[8px] rounded-[3px] shadow-sm">
                <div className="flex items-center space-x-4">
                  <input
                    type="checkbox"
                    checked={selectedMonths.includes(index)}
                    onChange={() => handleMonthSelection(index)}
                  />
                  <span className="text-[16px] leading-[18px] font-[700]">{month} {selectedYear}</span>
                </div>
                <a href="#" className="text-[#0000FF]" onClick={() => handleExportMonth(index)}>Download {month}</a>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Export;