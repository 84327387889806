import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import * as Realm from "realm-web";

const MyTimes = ({ setLoggedIn }) => {
  const [timeEntries, setTimeEntries] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [months, setMonths] = useState([]);
  const [selectedTab, setSelectedTab] = useState('pending');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showYearOverview, setShowYearOverview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingEntry, setEditingEntry] = useState({});
  const [comment, setComment] = useState('');
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);

  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getClients', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setClients(result);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getProjects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setProjects(result);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const fetchTasks = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTasks', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        setTasks(result);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchClients();
    fetchProjects();
    fetchTasks();
  }, [token]);

  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
            const refreshed = await refreshAccessToken();
            if (!refreshed) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              const newToken = app.currentUser.accessToken;
              const isValidNewToken = await validateToken(newToken);
              if (!isValidNewToken) {
                setLoggedIn(false);
                navigate('/login');
              } else {
                fetchEmployeeData();
                setLoggedIn(true);
              }
            }
          } else {
            setLoggedIn(false);
            navigate('/login');
          }
        } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              console.log(result);
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const fetchEmployeeData = async () => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({ token }),
      redirect: 'follow'
    };

    try {
      const response = await fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error fetching employee data:', result.error);
      } else {
        setEmployee(result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchEmployeeData();
  }, [token]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!employee) return;
  
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          email: employee.email,
        }),
        redirect: 'follow',
      };
  
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching time entries:', result.error);
          setLoading(false);
        } else {
          setTimeEntries(result); 
          const uniqueMonths = Array.from(new Set(result.map((entry) => entry.date.substring(0, 7))));
          setMonths(uniqueMonths); 
        }
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
  
    fetchInitialData(); 
  }, [employee, token]);
  
  const calculateTotalHours = (entries) => {
    let totalMinutes = 0;
    entries.forEach(entry => {
      if (entry.status === 'approved' || !entry.status) {
        const [startHours, startMinutes] = entry.startTime.split(':').map(Number);
        const [endHours, endMinutes] = entry.endTime.split(':').map(Number);
        const start = new Date(entry.date);
        const end = new Date(entry.date);
        start.setHours(startHours, startMinutes, 0);
        end.setHours(endHours, endMinutes, 0);
        let durationMinutes = (end - start) / 60000;
  
        let pause = '00:00';
        if (typeof entry.pause === 'string' && entry.pause.includes(':')) {
          pause = entry.pause;
        }
  
        const [pauseHours, pauseMinutes] = (pause || '00:00').split(':').map(Number);
        const pauseMinutesTotal = (pauseHours * 60) + pauseMinutes;
  
        durationMinutes -= pauseMinutesTotal;
  
        totalMinutes += durationMinutes;
      }
    });
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes, totalMinutes };
  };
  
  const renderYearlyOverview = () => (
    <div className="mt-8">
      <h2 className="text-2xl font-bold mb-6">Jahresübersicht</h2>
      {yearlyData.map(({ month, totalHours, targetHours }) => {
        const diffHours = totalHours - targetHours;
        const monthName = new Date(`${month}-01`).toLocaleString('de-DE', { year: 'numeric', month: 'long' });
  
        return (
          <div key={month} className="mt-4">
            <h2 className="text-xl font-bold mb-4">
              {monthName} 
            </h2>
            <div className="grid grid-cols-4 gap-[16px] mb-[32px]">
              <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
                <p className="text-[36px] font-[600] text-[#0000FF]">{Math.floor(targetHours)} Std</p> 
              </div>
              <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
                <p className="text-[36px] font-[600] text-[#0000FF]">{Math.floor(totalHours)} Std {((totalHours % 1) * 60).toFixed(0)} Min</p> 
              </div>
              <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] ${diffHours < 0 ? 'text-red-600' : 'text-green-600'}`} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                <h3 className="text-[16px] font-[500] text-[#101828]">{diffHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
                <p className="text-[36px] font-[600]">{diffHours < 0 ? '-' : '+'} {Math.abs(diffHours).toFixed(2)} Std</p> 
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  
  const renderMonthlyOverview = (month) => {
    const monthlyTimes = getMonthlyTimes(month);
    const totalHours = calculateTotalHours(monthlyTimes);
    const targetHours = employee?.hours_month || 0;
    const diffHours = (totalHours.totalMinutes / 60) - targetHours;
  
    return (
      <div key={month} className="mt-4" onClick={() => { setSelectedMonth(month); setShowYearOverview(false); }}>
        <h2 className="text-xl font-bold mb-4 cursor-pointer">{new Date(month).toLocaleString('de-DE', { year: 'numeric', month: 'long' })}</h2>
        <div className="grid grid-cols-4 gap-[16px] mb-[32px]">
          <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px] cursor-pointer" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
            <p className="text-[36px] font-[600] text-[#0000FF]">{targetHours} Std</p>
          </div>
          <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] cursor-pointer" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
            <p className="text-[36px] font-[600] text-[#0000FF]">{totalHours.hours} Std {totalHours.minutes} Min</p>
          </div>
          <div className={`bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] cursor-pointer ${diffHours === 0 ? 'text-green-600' : 'text-red-600'}`} style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
            <h3 className="text-[16px] font-[500] text-[#101828]">{diffHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
            <p className="text-[36px] font-[600]">{diffHours < 0 ? '-' : '+'} {Math.abs(diffHours).toFixed(2)} Std</p>
          </div>
        </div>
      </div>
    );
  };
  
  const calculateYearlyOverview = () => {
    const currentYear = new Date().getFullYear();
    const allMonths = generateAllMonthsForYear(currentYear);
  
    const yearlyOverview = allMonths.map(month => {
      const monthlyTimes = getMonthlyTimes(month);
      const totalHours = calculateTotalHours(monthlyTimes);
      const targetHours = employee?.hours_month;
  
      return {
        month,
        totalHours: totalHours.hours + (totalHours.minutes / 60),
        targetHours,
      };
    });
  
    setYearlyData(yearlyOverview);
  };

  useEffect(() => {
    if (showYearOverview) {
      calculateYearlyOverview();
    }
  }, [showYearOverview, months, timeEntries]);

  useEffect(() => {
    const fetchTimeEntriesForMonth = async () => {
      if (!employee || !selectedMonth || showYearOverview) return;

      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          token,
          email: employee.email,
          month: selectedMonth
        }),
        redirect: 'follow'
      };

      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching time entries:', result.error);
        } else {
          setTimeEntries(result);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchTimeEntriesForMonth();
  }, [employee, selectedMonth, token, showYearOverview]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearOverview = () => {
    setShowYearOverview(!showYearOverview);
    setSelectedMonth('');
    if (!showYearOverview) {
      const fetchYearOverview = async () => {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({
            token,
            email: employee.email
          }),
          redirect: 'follow'
        };

        try {
          const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTimes', requestOptions);
          const result = await response.json();
          if (result.error) {
            console.error('Error fetching time entries:', result.error);
          } else {
            setTimeEntries(result);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      fetchYearOverview();
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setSelectedRequest(null);
  };

  const handleRequestClick = (request) => {
    setSelectedRequest(request);
  };

  const getMonthlyTimes = (month) => {
    return timeEntries.filter(entry => entry.date.startsWith(month));
  };

  const monthlyTimes = getMonthlyTimes(selectedMonth);
  const totalHours = calculateTotalHours(monthlyTimes);
  const monthlyTargetHours = employee?.hours_month;

  const categorizedEntries = {
    pending: [],
    approved: [],
    denied: []
  };

  timeEntries.forEach(entry => {
    const categorizedEntry = {
      ...entry,
      type: 'time'
    };

    switch (entry.status) {
      case 'pending':
        categorizedEntries.pending.push(categorizedEntry);
        break;
      case 'approved':
        categorizedEntries.approved.push(categorizedEntry);
        break;
      case 'denied':
        categorizedEntries.denied.push(categorizedEntry);
        break;
      default:
        categorizedEntries.pending.push(categorizedEntry);
    }
  });

  const renderEntriesTable = (entries) => (
    <div className='h-[650px] overflow-auto'>
      <table className="w-full rounded-lg">
        <thead>
          <tr className="bg-[#FCFCFC]" style={{ borderBottom: "1px solid #EFEFF0" }}>
            <th className="pl-[32px] text-left text-[#7B97FB]">Datum</th>
            <th className="p-2 text-left text-[#7B97FB]">Dauer</th>
            <th className="p-2 text-left text-[#7B97FB]">Kunde</th>
            <th className="p-2 text-left text-[#7B97FB]">Projekt</th>
          </tr>
        </thead>
        <tbody className="bg-[#FCFCFC]">
          {entries.map(entry => {
            const client = clients.find(client => client._id === entry.customerId);
            const project = projects.find(project => project._id === entry.projectId);
            const clientName = client && Object.keys(client).length ? client.company.name : "";
            const projectName = project && Object.keys(project).length ? project.name : "";
  
            return (
              <tr key={entry._id || entry.id} onClick={() => handleRequestClick(entry)} className="cursor-pointer">
                <td className="pl-[32px] font-bold">{new Date(entry.date).toLocaleDateString("de-DE")}</td>
                <td className="p-2 font-bold">{getTimeDifference(entry.startTime, entry.endTime, entry.pause)}</td>
                <td className="p-2">{clientName}</td>
                <td className="p-2">{projectName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const generateAllMonthsForYear = (year) => {
    return Array.from({ length: 12 }, (_, i) => {
      const month = (i + 1).toString().padStart(2, '0');
      return `${year}-${month}`;
    });
  };

  const getTimeDifference = (startTime, endTime, pause) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0);

    const endDate = new Date();
    endDate.setHours(endHours, endMinutes, 0);

    let differenceInMilliseconds = endDate - startDate;

    if (differenceInMilliseconds < 0) {
      differenceInMilliseconds += 24 * 60 * 60 * 1000;
    }

    pause = '00:00';
    if (typeof pause === 'string' && pause.includes(':')) {
        pause = pause;
    }

    const pauseMilliseconds = pause ? parseInt(pause.split(':')[0]) * 3600000 + parseInt(pause.split(':')[1]) * 60000 : 0;
    differenceInMilliseconds -= pauseMilliseconds;

    const totalMinutes = Math.floor(differenceInMilliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const openEditDialog = () => {
    setEditingEntry(selectedRequest);
    console.log(selectedRequest)
    setComment(selectedRequest.comment || '');
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingEntry((prevEntry) => ({ ...prevEntry, [name]: value }));
  };

  const handleEditSubmit = async () => {
    const oldEntry = selectedRequest;
  
    const changeRequestData = {
      token,
      timeId: oldEntry.id,
      originalStartTime: oldEntry.startTime,
      originalEndTime: oldEntry.endTime,
      newStartTime: `${editingEntry.date}T${editingEntry.startTime}:00.000Z`,
      newEndTime: `${editingEntry.date}T${editingEntry.endTime}:00.000Z`,
      comment,
      project: oldEntry.projectId,
      client: oldEntry.customerId,
      task: oldEntry.taskId,
      leadResponse: false,
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify(changeRequestData),
      redirect: 'follow'
    };
  
    document.getElementById('edit-submit-btn').disabled = true;
  
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addChangeRequest', requestOptions);
      const result = await response.json();
      if (result.error) {
        console.error('Error creating change request:', result.error);
      } else {
        console.log('Change request created:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      document.getElementById('edit-submit-btn').disabled = false;
    }
  
    setIsEditDialogOpen(false);
  };
  
  return (
    <div className="bg-gray-100 min-h-screen py-[48px] px-[83px]">
      {loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
        }}>
          <Oval
            height={80}
            width={80}
            color="#0000FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0000FF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div className="mb-[32px]">
            <h1 className="text-2xl font-bold">Monatsübersicht</h1>
            <p className="text-sm text-gray-600">Meine Zeiten &gt; Aktueller Stundensoll</p>
          </div>
          {employee && (
            <>
              <div className="flex flex-col md:flex-row md:items-end mb-[32px]">
                {!showYearOverview && (
                  <div className="flex-grow">
                    <label htmlFor="month-select" className="block mb-2 text-gray-700">Übersicht für den Monat:</label>
                    <select id="month-select" className="w-full p-2 border border-gray-300 rounded" value={selectedMonth} onChange={handleMonthChange}>
                      <option value="" disabled>Wählen Sie einen Monat</option>
                      {months.map(month => (
                        <option key={month} value={month}>{new Date(month).toLocaleString('de-DE', { year: 'numeric', month: 'long' })}</option>
                      ))}
                    </select>
                  </div>
                )}
                <div className={`${showYearOverview ? "ml-0" : "ml-4"}`}>
                  <button className="bg-[#E8EDFF] text-[#0000FF] py-2 px-4 rounded min-h-[42.5px]" onClick={() => setShowYearOverview(!showYearOverview)}>
                    {showYearOverview ? 'Zur Monatsübersicht' : 'Zur Jahresübersicht'}
                  </button>
                </div>
              </div>
              {selectedMonth && !showYearOverview && (
                <div className="mt-4 grid grid-cols-4 gap-[16px] mb-[32px]">
                  <div className="bg-[#E8EDFF] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#0000FF]">Monatsziel</h3>
                    <p className="text-[36px] font-[600] text-[#0000FF]">{monthlyTargetHours} Std</p>
                  </div>
                  <div className="bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px]" style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#101828]">Erfasste Stunden</h3>
                    <p className="text-[36px] font-[600] text-[#0000FF]">{totalHours.hours} Std {totalHours.minutes} Min</p>
                  </div>
                  <div className='bg-[#FCFCFC] rounded-2xl py-[16px] pl-[32px] text-red-600' style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.16)' }}>
                    <h3 className="text-[16px] font-[500] text-[#101828]">{(totalHours.totalMinutes / 60) - monthlyTargetHours < 0 ? 'Minusstunden' : 'Überstunden'}</h3>
                    <p className="text-[36px] font-[600]">{(totalHours.totalMinutes / 60) - monthlyTargetHours < 0 ? '-' : '+'} {Math.abs((totalHours.totalMinutes / 60) - monthlyTargetHours).toFixed(2)} Std</p>
                  </div>
                </div>
              )}
              {showYearOverview && (
                <div>
                  {renderYearlyOverview()}
                </div>
              )}
              {selectedMonth && !showYearOverview && (
                <>
                  <div className="mt-4 grid grid-cols-3 gap-[16px] mb-[32px]">
                    <button
                      className={`p-2 rounded ${selectedTab === 'pending' ? 'bg-[#0000FF] text-white' : 'bg-[#C4D1FF] text-white'}`}
                      onClick={() => handleTabClick('pending')}
                    >
                      Nicht geprüfte Zeiten: {categorizedEntries.pending.length}
                    </button>
                    <button
                      className={`p-2 rounded ${selectedTab === 'approved' ? 'bg-[#0000FF] text-white' : 'bg-[#C4D1FF] text-white'}`}
                      onClick={() => handleTabClick('approved')}
                    >
                      Bestätigte Zeiten: {categorizedEntries.approved.length}
                    </button>
                    <button
                      className={`p-2 rounded ${selectedTab === 'denied' ? 'bg-[#0000FF] text-white' : 'bg-[#C4D1FF] text-white'}`}
                      onClick={() => handleTabClick('denied')}
                    >
                      Abgelehnte Zeiten: {categorizedEntries.denied.length}
                    </button>
                  </div>
                  <div className="flex">
                    <div className="flex-grow">
                      {renderEntriesTable(categorizedEntries[selectedTab])}
                    </div>
                    {selectedRequest && (
                      <div className='flex flex-col'>
                        <div className="bg-white rounded-lg shadow-lg ml-4 w-[300px] h-[550px] mb-[8px]">
                          <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Zeitdetails</h1>
                          <div className='p-4'>
                            <p className='font-bold mb-[8px]'>{new Date(selectedRequest.date).toLocaleDateString("de-DE")}</p>
                            <p className='mb-[24px]'>Start: {selectedRequest.startTime} Ende: {selectedRequest.endTime}</p>
                            <p>Gesamt:</p>
                            <p className='py-[8px] text-[#0000FF] font-bold text-[30px] leading-[38px] border-b mb-[12px]'>{getTimeDifference(selectedRequest.startTime, selectedRequest.endTime, selectedRequest.pause)}</p>
                            <p className='mb-[8px]'>
                              Kunde: 
                              {(clients.find(client => client._id === selectedRequest.customerId) && Object.keys(clients.find(client => client._id === selectedRequest.customerId)).length) 
                                ? clients.find(client => client._id === selectedRequest.customerId).company.name 
                                : ''}
                            </p>
                            <p className='mb-[8px]'>
                              Projekt: 
                              {(projects.find(project => project._id === selectedRequest.projectId) && Object.keys(projects.find(project => project._id === selectedRequest.projectId)).length) 
                                ? projects.find(project => project._id === selectedRequest.projectId).name 
                                : ''}
                            </p>
                            <p className='mb-[38px]'>
                              Tätigkeit: 
                              {(tasks.find(task => task._id === selectedRequest.taskId) && Object.keys(tasks.find(task => task._id === selectedRequest.taskId)).length) 
                                ? tasks.find(task => task._id === selectedRequest.taskId).name 
                                : ''}
                            </p>
                            <button className="bg-[#0000FF] text-white py-2 px-4 rounded" onClick={openEditDialog}>
                              Änderungsanfrage schicken
                            </button>
                          </div>
                        </div>
                        <div className='bg-white rounded-lg shadow-lg ml-4 w-[300px]'>
                          <h1 className="text-left text-[#7B97FB] border-b font-bold text-[18px] p-2 pl-4">Anmerkungen</h1>
                          <p className='p-4'>
                            {selectedRequest?.leadResponse ? selectedRequest.leadResponse : "Keine Anmerkung"}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
      {isEditDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full md:w-1/3 md:p-4">
            <h2 className="text-xl md:text-2xl font-bold mb-4">Edit Time Entry</h2>
            <div className="mb-4">
              <label className="block text-left">Date</label>
              <input
                type="date"
                name="date"
                value={editingEntry.date}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">Start Time</label>
              <input
                type="time"
                name="startTime"
                value={editingEntry.startTime}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">End Time</label>
              <input
                type="time"
                name="endTime"
                value={editingEntry.endTime}
                onChange={handleEditChange}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-left">Comment</label>
              <textarea
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="w-full border rounded px-2 py-1 md:px-3 md:py-2"
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="bg-[#0000FF] text-white py-1 px-2 md:py-2 md:px-4 rounded mr-2"
                onClick={handleEditSubmit}
                id="edit-submit-btn"
              >
                Speichern
              </button>
              <button
                type="button"
                className="text-[#0000FF] py-1 px-2 md:py-2 md:px-4 rounded"
                onClick={closeEditDialog}
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTimes;