import React, { useState } from 'react';

const EmployeeForm = ({ onCancel, onNext, user }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [workTimeModel, setWorkTimeModel] = useState('');
  const [monthlyWorkHours, setMonthlyWorkHours] = useState('');
  const [weeklyWorkHours, setWeeklyWorkHours] = useState('');
  const [holidayValue, setHolidayValue] = useState('');
  const [adminChecked, setAdminChecked] = useState(false);
  const [superAdminChecked, setSuperAdminChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const handleAdminCheckboxChange = (e) => {
    setAdminChecked(e.target.checked);
    if (!e.target.checked) {
      setSuperAdminChecked(false);
    }
  };

  const handleSuperAdminCheckboxChange = (e) => {
    setSuperAdminChecked(e.target.checked);
    if (e.target.checked) {
      setAdminChecked(true);
    }
  };

  const handleNoAdminCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setAdminChecked(false);
      setSuperAdminChecked(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!firstName) newErrors.firstName = 'Vorname ist erforderlich';
    if (!lastName) newErrors.lastName = 'Nachname ist erforderlich';
    if (!jobTitle) newErrors.jobTitle = 'Jobtitel ist erforderlich';
    if (!employeeId) newErrors.employeeId = 'Mitarbeiter-ID ist erforderlich';
    if (!workTimeModel) newErrors.employeeId = 'Arbeitszeitmodell ist erforderlich';
    if (!weeklyWorkHours) newErrors.employeeId = 'Wochenarbeitsstunden sind erforderlich';
    if (!monthlyWorkHours) newErrors.employeeId = 'Monatsarbeitsstunden sind erforderlich';
    if (!holidayValue) newErrors.employeeId = 'Urlaubstage sind erforderlich';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    onNext({
      firstName,
      lastName,
      jobTitle,
      employeeId,
      workTimeModel,
      monthlyWorkHours,
      weeklyWorkHours,
      adminChecked,
      superAdminChecked,
      holidayValue
    });
  };

  return (
    <div className="bg-gray-100 w-full max-w-screen-xl mx-auto">
      <header className="flex justify-between items-center mb-[32px]">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Neuen Mitarbeiter anlegen</h1>
          <p className="text-sm text-gray-600">Alle Mitarbeiter &gt; Neuen Mitarbeiter anlegen</p>
        </div>
      </header>

      <h2 className="text-xl font-bold text-gray-800 mb-[24px]">Persönliche Informationen</h2>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-6 mb-[32px]">
          <div>
            <label className="block text-gray-700 mb-1">Vorname</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Namen eingeben"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Nachname</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Namen eingeben"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Jobtitel</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="z.B. Lagerist"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
            {errors.jobTitle && <p className="text-red-500 text-sm">{errors.jobTitle}</p>}
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Mitarbeiter-ID</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="111-222-333-444"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
            {errors.employeeId && <p className="text-red-500 text-sm">{errors.employeeId}</p>}
          </div>
          <div className="col-span-2">
            <label className="block text-gray-700 mb-1">Arbeitszeitmodell</label>
            <select
              className="w-full p-2 border border-gray-300 rounded-md"
              value={workTimeModel}
              onChange={(e) => setWorkTimeModel(e.target.value)}
            >
              <option value="">Arbeitszeitmodell auswählen</option>
              <option value="Vollzeit">Vollzeit</option>
              <option value="Teilzeit">Teilzeit</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Monatsarbeitsstunden</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Monatsarbeitsstunden eingeben"
              value={monthlyWorkHours}
              onChange={(e) => setMonthlyWorkHours(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Wochenarbeitsstunden</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Wochenarbeitsstunden eingeben"
              value={weeklyWorkHours}
              onChange={(e) => setWeeklyWorkHours(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Urlaubstage</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Urlaubstage eingeben"
              value={holidayValue}
              onChange={(e) => setHolidayValue(e.target.value)}
            />
          </div>
        </div>

        {
          user?.isSuperAdmin &&
          <div className="mb-6">
            <h3 className="text-lg font-bold text-gray-800 mb-2">Als Admin anlegen?</h3>
            <p className="text-sm text-gray-600 mb-4">Admins können Mitarbeiter und Zeiten für zugewiesene Projekte verwalten. Die Zeiten aller Mitarbeiter, die zu diesem Projekten gehören, können so verwaltet werden.</p>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                className="mr-2"
                checked={adminChecked && !superAdminChecked}
                onChange={handleAdminCheckboxChange}
                disabled={superAdminChecked}
              />
              <label className="text-gray-700">Ja, als Admin anlegen</label>
            </div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={superAdminChecked}
                  onChange={handleSuperAdminCheckboxChange}
                />
                <label className="text-gray-700">Ja, als Super Admin anlegen</label>
              </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={!adminChecked && !superAdminChecked}
                onChange={handleNoAdminCheckboxChange}
              />
              <label className="text-gray-700">Nein, nicht als Admin anlegen</label>
            </div>
          </div>
        }

        <div className="flex space-x-4">
          <button type="submit" className="bg-[#0000FF] text-white py-2 px-4 rounded-md font-[500]">Weiter</button>
          <button
            type="button"
            onClick={onCancel}
            className="text-[#0000FF] py-2 px-4 rounded-md font-[500]"
          >
            Abbrechen
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;
