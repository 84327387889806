import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/solid';
import * as Realm from "realm-web";

import EmployeeForm from '../EmployeeForm';
import EmployeeAccessForm from '../EmployeeAccessForm';
import EmployeeDetails from '../EmployeeDetails';

const EmployeeAdministration = ({ setLoggedIn }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [user, setUser] = useState();

  const token = localStorage.getItem('accessToken');
  const tokenCompany = localStorage.getItem('company');

  const handleAddEmployeeClick = () => {
    setCurrentStep(2);
  };

  const handleCancelClick = () => {
    setCurrentStep(1);
  };

  const handleNextClick = (info) => {
    setEmployeeInfo(info);
    setCurrentStep(3);
  };

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
    setCurrentStep(4);
  };

  const navigate = useNavigate();

  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);
  
  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              console.log(result);
              setUser(result)
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  useEffect(() => {
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': '*',
      },
      body: JSON.stringify({
        token: token,
        company: tokenCompany
      }),
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_EMPLOYEELIST_URL, requestOptions)
      .then(response => response.json())
      .then(result => {
        const sortedList = result.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setEmployeeList(sortedList);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [token, tokenCompany]);

  useEffect(() => {
    const results = employeeList?.filter(employee =>
      `${employee.firstName} ${employee.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, employeeList]);

  const groupEmployeesByProject = (employees) => {
    console.log(employees)
    const grouped = {};
    
    if (employees) {
      employees?.forEach(employee => {
        const projectEntries = Object.entries(employee.projects);
        if (projectEntries.length === 0) {
          if (!grouped['No Project']) {
            grouped['No Project'] = [];
          }
          grouped['No Project'].push(employee);
        } else {
          projectEntries.forEach(([projectId, projectName]) => {
            if (!grouped[projectName]) {
              grouped[projectName] = [];
            }
            grouped[projectName].push(employee);
          });
        }
      });
    }

    return grouped;
  };

  const groupedEmployees = groupEmployeesByProject(employeeList);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col py-[48px] px-[83px]">
      {currentStep === 1 && (
        <>
          <header className="flex justify-between items-center w-full max-w-screen-xl mb-[32px]">
            <div>
              <h1 className="text-2xl font-bold text-gray-800">Aktuelle Mitarbeiter:innen</h1>
              <p className="text-sm text-gray-600">Zeigt alle angelegten Mitarbeiter an.</p>
            </div>
            <button 
              onClick={handleAddEmployeeClick} 
              className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
            >
              Mitarbeiter hinzufügen
            </button>
          </header>
          
          <div className="w-full max-w-screen-xl mx-auto mb-6">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Nach Mitarbeiter suchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <ul className="bg-white shadow-md rounded-lg mt-2 p-2">
                {searchResults.map(employee => (
                  <li 
                    key={employee._id} 
                    onClick={() => handleEmployeeClick(employee)}
                    className="cursor-pointer hover:bg-gray-200 p-2 rounded-md text-[#0000FF] flex items-center"
                  >
                    <UserIcon className="h-5 w-5 mr-2" />
                    {employee.firstName} {employee.lastName}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="w-full max-w-screen-xl mx-auto">
            {Object.entries(groupedEmployees).map(([projectName, employees]) => (
              <div key={projectName} className="mb-[32px]">
                <h2 className="text-xl font-bold text-[#0000FF] mb-4">
                  {projectName}: {employees.length}
                </h2>
                <ul className="bg-white shadow-md rounded-lg p-4">
                  {employees?.map(employee => (
                    <li 
                      key={employee._id} 
                      onClick={() => handleEmployeeClick(employee)}
                      className="cursor-pointer hover:bg-gray-200 p-2 rounded-md flex items-center font-medium"
                    >
                      <UserIcon className="h-5 w-5 mr-2" />
                      {employee.firstName} {employee.lastName}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </>
      )}
      {currentStep === 2 && <EmployeeForm onCancel={handleCancelClick} onNext={handleNextClick} user={user} />}
      {currentStep === 3 && <EmployeeAccessForm onCancel={handleCancelClick} employeeInfo={employeeInfo} />}
      {currentStep === 4 && selectedEmployee && (
        <EmployeeDetails employee={selectedEmployee} onBack={handleCancelClick} allEmployees={employeeList} setEmployeeList={setEmployeeList} user={user}/>
      )}
    </div>
  );
};

export default EmployeeAdministration;